import React from 'react';
import { Button, Form, Input, Layout, Modal } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { userRegisterUrl } from '../../api/api';



const Register = () => {

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState('Please Wait...');
  const [success, setSuccess] = useState(false);

  const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
        if(success){
          setSuccess(false);
          window.location.reload(true);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

  const onFinish = (values) => {
    const { username, password, invitationCode } = values;
    axios.post(userRegisterUrl, { username, password, invitationCode }).then(res => {
      // console.log(res.data);
      if (res.data.errno === 0) {
        setModalText('Register Success!');
        setSuccess(true);
        Form.resetFields();
      } else {
        setModalText(res.data.message);
      }
    })
  };
  
  const onFinishFailed = (errorInfo) => {
    setModalText('Register failed');
    // console.log('Failed:', errorInfo);
  };
  
  return (
<Layout style={{height:'60vh', width:'95vw'}}>
  <Form
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600, margin: 'auto' }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Username"
      name="username"
      rules={[{ required: true, message: 'Please input your username!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[{ required: true, message: 'Please input your password!' }]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      label="邀请码"
      name="invitationCode"
      rules={[{ required: true, message: 'Please input your password!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
      <Button type="primary" htmlType="submit" onClick={showModal} style={{margin:'auto'}}>
        Register
      </Button>
      <Modal 
                title="Submit Result"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>{modalText}</p>
            </Modal>
    </Form.Item>
  </Form>
</Layout>
)};

export default Register;