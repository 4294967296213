// import type { ColumnsType, TableProps, ColumnType} from 'antd/es/table';
import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
// import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
// import type { FilterConfirmProps } from 'antd/es/table/interface';
// import Highlighter from 'react-highlight-words';
import ModalComponent from '../../components/modal/modal';
import AddNew from './add-new';
import Users from '../users/users';
import axios from 'axios';

import { getRegisterUrl, deleteRegisterUrl } from '../../api/api.js';


// interface DataType {
//   key: React.Key;
//   name: string;
//   date: string;
//   nric: string;
//   nationality: string;
//   contact:number;
//   timein: string;
//   timeout: string;
//   signature: string;
//   therapist: string;
//   roomno: number;
// }

// type DataIndex = keyof DataType;

// const columns: ColumnsType<DataType> = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     filters: [
//       {
//         text: 'Joe',
//         value: 'Joe',
//       },
//       {
//         text: 'Jim',
//         value: 'Jim',
//       },
//       {
//         text: 'Submenu',
//         value: 'Submenu',
//         children: [
//           {
//             text: 'Green',
//             value: 'Green',
//           },
//           {
//             text: 'Black',
//             value: 'Black',
//           },
//         ],
//       },
//     ],
//     // specify the condition of filtering result
//     // here is that finding the name started with `value`
//     onFilter: (value: string, record) => record.name.indexOf(value) === 0,
//     sorter: (a, b) => a.name.length - b.name.length,
//     sortDirections: ['descend'],
//   },
//   {
//     title: 'Age',
//     dataIndex: 'age',
//     defaultSortOrder: 'descend',
//     sorter: (a, b) => a.age - b.age,
//   },
//   {
//     title: 'Address',
//     dataIndex: 'address',
//     filters: [
//       {
//         text: 'London',
//         value: 'London',
//       },
//       {
//         text: 'New York',
//         value: 'New York',
//       },
      
//     ],
//     onFilter: (value: string, record) => record.address.indexOf(value) === 0,
//   },
//    {
//     title: 'Action',
//     key: 'action',
//     render: (_, record) => (
//       <Space size="middle">
//         <a>Edit</a>
//         <a>Delete</a>
//       </Space>
//     ),
//   },
// ];

const onChange = (pagination, filters, sorter, extra) => {
  // console.log('params', pagination, filters, sorter, extra);
};

const DailyRegister = (props) => {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  
   useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        getRegisterUrl)
        // console.log(result.data.data.reverse())
        setData(result.data.data.filter((item) => item.name !== ''))
    }
    fetchData()
  }, [])

  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleDelete = (id) => {
    console.log('handleDelete', id)
    const deletedata = {};
    deletedata.id = id;
    axios.post(deleteRegisterUrl, deletedata).then(res => {
      if (res.data.errno === 0) {
        window.location.reload(true);
      } else {
        console.log(res.data.message);
      }
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex)=> ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys , confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
      {/*    <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('date'),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name')
    },
    {
      title: 'IC',
      dataIndex: 'ic',
      key: 'ic',
      ...getColumnSearchProps('ic')
    },
    {
      title: 'CONTACT',
      dataIndex: 'contact',
      key: 'contact',
      ...getColumnSearchProps('contact')
    },
    {
      title: 'TIME IN',
      dataIndex: 'timein',
      key: 'timein',
      ...getColumnSearchProps('timein')
    },
    {
      title: 'TIME OUT',
      dataIndex: 'timeout',
      key: 'timeout',
      ...getColumnSearchProps('timeout')
    },
    {
      title: 'THERAPIST',
      dataIndex: 'therapist',
      key: 'therapist',
      ...getColumnSearchProps('therapist')
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, data = {...getColumnSearchProps('id')}) => (
        <Space size="middle">
          <ModalComponent 
            title="Edit"
            buttonLabel="Edit"
            component={<AddNew id={data.id} data={data} modal='edit'/>}
          />
          <Button onClick={() => handleDelete(data.id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  if(props.user === 'admin'){
    return <Table columns={columns} dataSource={data} onChange={onChange} />
  } else if (props.user === 'guest'){
    return (
      <div>
        <Users user="guest" />
        <h1>You are not allowed to view this page, please log in</h1>
      </div>
    )
  }
};

export default DailyRegister;