const hostname = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;

const getRegisterUrl = `${protocol}//${hostname}:${port}/api/register/list`;
const createRegisterUrl = `${protocol}//${hostname}:${port}/api/register/new`;
const updateRegisterUrl = `${protocol}//${hostname}:${port}/api/register/update`;
const deleteRegisterUrl = `${protocol}//${hostname}:${port}/api/register/delete`;
const getAutoCompleteUrl = `${protocol}//${hostname}:${port}/api/register/auto`;

const getTherapistFormUrl = `${protocol}//${hostname}:${port}/api/form/list`;
const createTherapistFormUrl = `${protocol}//${hostname}:${port}/api/form/new`;
const updateTherapistFormUrl = `${protocol}//${hostname}:${port}/api/form/update`;
const deleteTherapistFormUrl = `${protocol}//${hostname}:${port}/api/form/delete`;

const userLoginUrl = `${protocol}//${hostname}:${port}/api/user/login`;
const userLogoutUrl = `${protocol}//${hostname}:${port}/api/user/logout`;
const userRegisterUrl = `${protocol}//${hostname}:${port}/api/user/register`;
const loginCheckUrl = `${protocol}//${hostname}:${port}/api/user/logincheck`;

module.exports = {
    getRegisterUrl,
    createRegisterUrl,
    updateRegisterUrl,
    deleteRegisterUrl,
    getTherapistFormUrl,
    createTherapistFormUrl,
    updateTherapistFormUrl,
    deleteTherapistFormUrl,
    userLoginUrl,
    userRegisterUrl,
    userLogoutUrl,
    loginCheckUrl,
    getAutoCompleteUrl,
}