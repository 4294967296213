import React from 'react';
import { Button, Form, Input, Select, DatePicker, TimePicker, Modal, AutoComplete } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { createRegisterUrl, updateRegisterUrl, getRegisterUrl, getAutoCompleteUrl } from '../../api/api.js';
import axios from 'axios';
import './add-new.css';
import { arrayMoveImmutable } from '@ant-design/pro-components';
import CryptoJS from 'crypto-js';
import { SECRET_KEY_AUTO } from '../../key/key.js';



const AddNew = (props) => {
    const [form] = Form.useForm()
    const [options, setOptions] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [filteredResult, setFilteredResult] = useState([]);
    const [showNumber, setShowNumber] = useState(1);
    const [name, setName] = useState('');

    useEffect(() => {
        axios.get(`${getAutoCompleteUrl}?name=${name}`).then(res => {
            const originalData = CryptoJS.AES.decrypt(res.data.message, SECRET_KEY_AUTO).toString(CryptoJS.enc.Utf8);
            // console.log(JSON.parse(originalData).map(i => i.name))
            const result = JSON.parse(originalData).map(
                i => ({
                    name: i.name,
                    ic: i.ic,
                    nationality: i.nationality,
                    contact: i.contact,
                })
            )
            // const result = originalData.data.data.map(
            //     i => ({
            //         name: i.name,
            //         ic: i.ic,
            //         nationality:i.nationality,
            //         contact:i.contact,
            //     })
            // )
            // const customerListData = result.map(i => i.name)
            setCustomerList(result)
            // console.log('name',customerList)
            // console.log('result',result)
        }).catch(err => {
            console.log(err)
        }
        )
    }, [])

    const onSearch = (search, show = 0) => {
        // console.log(search)
        let initResult = customerList.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
        let replace = 'NA******'
        let result = initResult.map(i => `${i.name}:${i.contact || replace}-${i.ic || replace}-${i.nationality || replace}`)
        result = Array.from(new Set(result))
        setFilteredResult(result)
        // console.log('filter',filteredResult)
        let resultShow = initResult.map(i => `${i.name} (phone: ${i.contact.substring(0, 4)}****)`)
        resultShow = Array.from(new Set(resultShow))
        // console.log('result',resultShow)
        // setShowNumber(result.length || 1)
        return {
            value:resultShow[show],
            key: show
        }
    }

    const debounce = (fn, delay) => {
        let timer = null;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };
    
    const getPanelValue = (searchText) => {
        // let arr = [];
        // for(let i = 0; i < showNumber; i++){
        //     arr.push(onSearch(searchText, i))
        // }
        // eslint-disable-next-line react-hooks/rules-of-hooks

        // console.log('search',searchText)
        setName(searchText)
        // console.log('name',name)
        // console.log('penal',customerList.length)
        if(customerList.length > 0){
            setOptions(!searchText ? [] : [onSearch(searchText), onSearch(searchText, 1), onSearch(searchText, 2)]);
        }else{
            setOptions([])
        }
    }

    const onAutoChange = (data, key) => {
        // console.log('data & key', key)
        // console.log(filteredResult[key.key])
        if(filteredResult[key.key]){
            let name = filteredResult[key.key].split(':')[0]
            let ic = filteredResult[key.key].split(':')[1].split('-')[1]
            let contact = filteredResult[key.key].split(':')[1].split('-')[0]
            let nationality = filteredResult[key.key].split(':')[1].split('-')[2]
            if (contact === 'NA******') contact = ''
            if (ic === 'NA******') ic = ''
            if (nationality === 'NA******') nationality = ''
            form.setFieldsValue({
                name: name,
                ic: ic,
                contact: contact,
                nationality: nationality
            })
        }
    };

    // const [require, setRequire] = useState(true)

    // const [update, setUpdate] = useState({
    //     therapist: "",
    //     room: "",
    //     name: "",
    //     ic: "",
    //     date: dayjs(Date.now()).format('YYYY-MM-DD'),
    //     timein: dayjs(Date.now()).format('HH:mm'),
    //     timeout: dayjs(Date.now()).format('HH:mm'),
    //     nationality: "",
    //     contact: "",
    // });
    
    useEffect(() => {
        if(props.data){
            // setUpdate({
            //     therapist: props.data.therapist,
            //     room: props.data.room,
            //     name: props.data.name,
            //     ic: props.data.ic,
            //     date:props.data.date, dateFormat, 
            //     timein: props.data.timein,
            //     timeout: props.data.timeout,
            //     nationality: props.data.nationality,
            //     contact: props.data.contact,
            // })
            form.setFieldsValue({
                therapist: props.data.therapist,
                room: props.data.room,
                name: props.data.name,
                date: dayjs(props.data.date, dateFormat),
                timein: dayjs(props.data.timein, format),
                timeout: dayjs(props.data.timeout, format),
                ic: props.data.ic,
                nationality: props.data.nationality,
                contact: props.data.contact,
            })
        }
        // console.log(props.data)
        // form.resetFields();
        // setRequire(false)
    }, [props.data])

    let signCanvas = {}

    const onClear = () => {
            if (signCanvas.isEmpty()) {
                return;
            }
            signCanvas.clear()
        }

    
    const [signature, setSignature] = useState(null);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Please Wait...');
    const [errorMsg, setErrorMsg] = useState(null);
    const [success, setSuccess] = useState(false);

    const dateToday = dayjs(Date.now()).format('YYYY-MM-DD');
    const dateFormat = 'YYYY-MM-DD';
    const timenow = dayjs(Date.now()).format('HH:mm');
    const timeafter = dayjs(Date.now()).add(1, 'hour').format('HH:mm');
    
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 500);
        if(success){
            setSuccess(false);
            setTimeout(() => {
                window.location.reload(true);
            }, 500);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (values) => {
        values.date = dayjs(values.date).format('YYYY-MM-DD')
        values.timein = dayjs(values.timein).format('HH:mm')
        values.timeout = dayjs(values.timeout).format('HH:mm')
        if (props.id) {
            // values.date = props.data.date
            // values.timein = props.data.timein
            // values.timeout = props.data.timout
            values.id = props.id
            // console.log('modal',values)
            axios.post(updateRegisterUrl, values)
            .then(res => {
                // console.log(res);
                // console.log(res.data);
                setModalText('Successfully updated');
                setSuccess(true);
                // let errorMsg = 'Please refresh to see changes';
                // setErrorMsg(<p>{errorMsg}</p>);
            })
        } else {
            setSignature(signCanvas.getTrimmedCanvas().toDataURL('image/png'));
            fetch(signCanvas.getTrimmedCanvas().toDataURL('image/png')).then(
                res => res.blob()).then(blob => {values.signature = blob}
            )
            // console.log(values)
            axios.post(createRegisterUrl, values)
            .then(res => {
                // console.log(res);
                // console.log(res.data);
                setModalText('Successfully added new record');
                // let errorMsg = 'Please navigate to records page to see changes';
                // setErrorMsg(<p>{errorMsg}</p>);
                // form.resetFields();
            })
        }
        form.resetFields();
        // setRequire(true)
        // console.log('Success:', values, signature);
    };
    
    const onFinishFailed = (errorInfo) => {
        let errorMsg = errorInfo.errorFields.map(i => i.errors);
        errorMsg = errorMsg.map(i => <p>{i}</p>)
        setModalText('Failed to add new record');
        setErrorMsg(<p>{errorMsg}</p>);
        // console.log('Failed:', errorInfo.errorFields.map(i => i.errors));
    };
    const onReset = () => {
        signCanvas.clear();
        form.resetFields();
    };
    const onChange = (date, dateString) => {
        
        // console.log(date,dateString);
    }
    // const therapist = []
    const format = 'HH:mm';
    if (props.modal == null) {
            return (
                <div className='addnew'>
                    <Form
                    form={form}
                    name="control-hooks"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 0 }}
                    style={{ maxWidth: 900}}
                    initialValues={{ disclaimer: false }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on"
                    labelAlign='left'
                    allowClear
                    >
                    <Form.Item
                        name="therapist"
                        label="Therapist"
                        rules={[{ required: false, message: 'Please select therapist!' }]}  
                    >
                        {/**<Select>
                            {therapist.map((therapist) => 
                                <Option value={therapist}>
                                    {therapist}
                                </Option>)}
                            </Select>*/}
                        <Input defaultValue={''}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Date"
                    name="date"
                    rules={[{ required: false, message: 'Please select the date!' }]}
                    >
                    <DatePicker onChange={onChange} defaultValue={dayjs(dateToday, dateFormat)}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        {/*<Input defaultValue={''}/>*/}
                    <AutoComplete 
                        options={options}
                        onSearch={debounce((text) => getPanelValue(text), 500)}
                        defaultValue={''}
                        onSelect={onAutoChange}
                    />
                    </Form.Item>
        
                    <Form.Item
                    label="NRIC / PASSPORT No."
                    name="ic"
                    rules={[{ required: false, message: 'Please input your ic' }]}
                    >
                    <Input defaultValue={''}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{ required: false, message: 'Please input your nationality!' }]}
                    >
                    <Input defaultValue={''}/>
                    </Form.Item>
                    
                    <Form.Item
                    label="Contact No."
                    name="contact"
                    rules={[{ required: false, message: 'Please input your contact number!' }]}
                    >
                    <Input defaultValue={''}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Time In"
                    name="timein"
                    rules={[{ required: false, message: 'Please input the time in!' }]}
                    >
                    <TimePicker defaultValue={dayjs(timenow, format)} format={format} onChange={onChange} />
                    </Form.Item>
                    
                    <Form.Item
                    label="Time Out"
                    name="timeout"
                    rules={[{ required: false, message: 'Please input the time out!' }]}
                    >
                    <TimePicker defaultValue={dayjs(timeafter, format)} format={format} onChange={onChange} />
                    </Form.Item>
        
                    <Form.Item
                    label="Room No."
                    name="room"
                    rules={[{ required: false, message: 'Please input your room number!' }]}
                    >
                    <Input defaultValue={''}/>
                    </Form.Item>                
        
                    <Form.Item
                    label="Signature"
                    name="signature"
                    rules={[{ required: false, message: 'Please sign here' }]}
                    >
                        <SignatureCanvas 
                        penColor='black' 
                        canvasProps={{ className: 'sigCanvas'}} 
                        ref={(ref) => { signCanvas = ref }}
                        />           
                        <Button htmlType="button" onClick={onClear}> Clear </Button>
                    </Form.Item>
        
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" onClick={showModal}>
                        Submit
                    </Button>
                    <Modal 
                        title="Submit Result"
                        open={open}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        confirmLoading={confirmLoading}
                    >
                        <p>{modalText}</p>
                        <p className='error'>{errorMsg}</p>
                    </Modal>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                    </Form.Item>
                    
                    </Form>
                </div>
            );
        } else if (props.modal === 'edit') {
           return (
                <div className='addnew'>
                    <Form
                    form={form}
                    name="control-hooks"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 0 }}
                    style={{ maxWidth: 900}}
                    initialValues={{ 
                        disclaimer: false}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelAlign='left'
                    allowClear
                    >
                    <Form.Item
                        name="therapist"
                        label="Therapist"
                        rules={[{ required: false, message: 'Please select therapist!' }]}  
                    >
                        {/**<Select>
                            {therapist.map((therapist) => 
                                <Option value={therapist}>
                                    {therapist}
                                </Option>)}
                            </Select>*/}
                        <Input 
                        defaultValue={props.data.therapist}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Date"
                    name="date"
                    rules={[{ required: false, message: 'Please select the date!' }]}
                    >
                    <DatePicker 
                    onChange={onChange} 
                    defaultValue={dayjs(props.data.date, dateFormat)}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: false, message: 'Please input your name!' }]}
                    >
                    <Input 
                    defaultValue={props.data.name}/>
                    </Form.Item>
        
                    <Form.Item
                    label="NRIC / PASSPORT No."
                    name="ic"
                    rules={[{ required: false, message: 'Please input your ic' }]}
                    >
                    <Input 
                    defaultValue={props.data.ic}/>
                    </Form.Item>
        
                    <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{ required: false, message: 'Please input your nationality!' }]}
                    >
                    <Input 
                    defaultValue={props.data.nationality}/>
                    </Form.Item>
                    
                    <Form.Item
                    label="Contact No."
                    name="contact"
                    rules={[{ required: false, message: 'Please input your contact number!' }]}
                    >
                    <Input 
                    defaultValue={props.data.contact}
                    initialValues={{ contact: props.data.contact }}
                    />
                    </Form.Item>
        
                    <Form.Item
                    label="Time In"
                    name="timein"
                    rules={[{ required: false, message: 'Please input the time in!' }]}
                    >
                    <TimePicker 
                    defaultValue={dayjs(props.data.timein, format)}
                    format={format}
                    onChange={onChange} />
                    </Form.Item>
                    
                    <Form.Item
                    label="Time Out"
                    name="timeout"
                    rules={[{ required: false, message: 'Please input the time out!' }]}
                    >
                    <TimePicker
                    defaultValue={dayjs(props.data.timeout, format)}
                    format={format} 
                    onChange={onChange} />
                    </Form.Item>
        
                    <Form.Item
                    label="Room No."
                    name="room"
                    rules={[{ required: false, message: 'Please input your room number!' }]}
                    >
                    <Input 
                    defaultValue={props.data.room}/>
                    </Form.Item>                

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" onClick={showModal}>
                        Submit
                    </Button>
                    <Modal 
                        title="Submit Result"
                        open={open}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        confirmLoading={confirmLoading}
                    >
                        <p>{modalText}</p>
                        <p className='error'>{errorMsg}</p>
                    </Modal>
                    <Button htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                    </Form.Item>
                    
                    </Form>
                </div>
            );
        }
    }

export default AddNew;