import React, { useState, useEffect } from 'react';
import {Routes, Route, useNavigate} from "react-router-dom";
import {
  FileOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';
// import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import Users from '../users/users';
import Therapist from '../therapist-files/therapist';
import DailyRegister from '../daily-register/daily-register';
import AddNew from '../daily-register/add-new';
import { loginCheckUrl } from '../../api/api';
import axios from 'axios';

import './main.css';
import Register from '../user-register/register';

const { Header, Content, Footer } = Layout;

// type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label,
  key,
  icon,
  children,
) {
  return {
    key,
    icon,
    children,
    label,
  } ;
}


const items = [
  getItem('Daily Register', '/dailyregister/addnew', <SolutionOutlined />),
  getItem('Therapist Files', '/therapist', <FileOutlined />),
  getItem('Records', '/dailyregister/records', <FileOutlined />),
  getItem('Login', '/user/login', <UserOutlined />),
  getItem('Register', '/user/register', <UserOutlined />),
];

const Main = () => {
  // const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState('guest');

  useEffect(() => {
    axios.get(loginCheckUrl).then(res => {
      if(res.data.login){
        setUser('admin');
        return
      }
      setUser('guest');
    })
  }, [user])
        
    
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/*<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} collapsedWidth={"5vw"}>
      <div style={{ height: 10, margin: 0, background: 'rgba(255, 255, 255, 0.2)' }} />
        <Menu theme="dark" defaultSelectedKeys={['']} mode="inline" items={items} onClick={
          ({key}) => {navigate(key)}
        }
        /> 
      </Sider>*/}
      <Header className="header" position='sticky'>
        <Menu theme="dark" defaultSelectedKeys={['Daily Register']} mode="horizontal"  items={items} onClick={
          ({key}) => {navigate(key)}
        }
        /> 
      </Header>
      <Layout className="site-layout" style={{overflow:'hidden'}}>
        <Content style={{ margin: 'auto', width:'100vw', padding:'5px' }}>
        <Routes>
            {/* <Route path="/" element={<Users user="guest"/>} /> */}
            <Route path="/" element={<AddNew />}/>
            <Route path="/user/login" element={<Users user={user}/>} />
            <Route path="/therapist" element={<Therapist />} />
            <Route path="/dailyRegister/records" element={<DailyRegister user={user}/>} />
            <Route path="/dailyRegister/addnew" element={<AddNew />}/>
            <Route path="/user/register" element={<Register />}/>
      {/* <Route path="*" element={<h1>Not Found!</h1>} /> */}
        </Routes>

          {/*<Breadcrumb style={{ margin: '0' }}>
            <Breadcrumb.Item>Daily Register</Breadcrumb.Item>
            <Breadcrumb.Item>All</Breadcrumb.Item>
          </Breadcrumb>*/}
        </Content>
        <Footer style={{ textAlign: 'center' }}> Sante Wellness ©2023 Created by Ocean</Footer>
      </Layout>
    </Layout>
  );
};

export default Main;