import React from 'react';
import { useState } from 'react';
import ModalComponent from '../../components/modal/modal';
import Login from '../log-in/login';
import { Button } from 'antd';
import { userLogoutUrl } from '../../api/api';

import './users.css';
import axios from 'axios';

const Users = (props) => {
    const onLogOut = () => {
        axios.post(userLogoutUrl).then(res => {
            // console.log(res.data.errno);
        })
        window.location.reload(true);
    }

    if(props.user === 'guest'){
        return (
            <div className='users'>
                <h1>You are under {props.user} view</h1>
                <br />
                <ModalComponent
                    component={<Login />}
                    title="Log In"
                    buttonLabel="Log In"
                />
            </div>
        );
    } else if(props.user === 'admin') {
        return (
            <div className='users'>
                <h1>You are under {props.user} view</h1>
                <br />
                <Button type="primary" danger onClick={onLogOut}>Log out</Button>
            </div>
        )
    }
}

export default Users;