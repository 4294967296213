import React from 'react';
import { Button, Checkbox, Form, Input, Select, DatePicker, Radio,Table, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';
import axios from 'axios';
import { useState } from 'react';

import './therapist.css';
import { createTherapistFormUrl } from '../../api/api';

// const therapist = []



const Therapist = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Please Wait...');
    const [errorMsg, setErrorMsg] = useState(null);    
    const [signature, setSignature] = useState(null);
    
    let signCanvas = {}
    const dateToday = dayjs(Date.now()).format('YYYY-MM-DD');
    const dateFormat = 'YYYY-MM-DD';
    const onClear = () => {
            if (signCanvas.isEmpty()) {
                return;
            }
            signCanvas.clear()
        }
    
    
    const { Option } = Select;
    const [form] = Form.useForm()
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 500);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onFinish = (values) => {
        setSignature(signCanvas.getTrimmedCanvas().toDataURL('image/png'));
        fetch(signCanvas.getTrimmedCanvas().toDataURL('image/png')).then(
            res => res.blob()).then(blob => {values.signature = blob}
        )
        values.dob = dayjs(values.dob).format('YYYY-MM-DD')
        values.date = dayjs(values.date).format('YYYY-MM-DD')
        values.medHistory = medHistory
        axios.post(createTherapistFormUrl, values).then(res => {
                // console.log(res);
                // console.log(res.data);
                setModalText('Successfully added new record');
                setErrorMsg(null);
            })
        form.resetFields();
        // console.log('Success:', values, signature, medHistory);
    };
    
    const onFinishFailed = (errorInfo) => {
        let errorMsg = errorInfo.errorFields.map(i => i.errors);
        errorMsg = errorMsg.map(i => <p>{i}</p>)
        setModalText('Failed to add new record');
        setErrorMsg(<p>{errorMsg}</p>);
        // console.log('Failed:', errorInfo);
    };
    const onReset = () => {
        form.resetFields();
    };
    const onChange = (date, dateString) => {
        // console.log(date,dateString);
    }
    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    const [medHistory, setMedHistory] = React.useState([]);

    const onChangeMedHistory = ({ target: { value, name } }) => {
        if (value && !medHistory.includes(name)) {
            setMedHistory([...medHistory, name]);
        } else {
            setMedHistory(medHistory.filter((item) => item !== name));
        }
    };

    const columns = [
        {
            title:'Medical History',
            key:'medicalHistory',
            dataIndex:'question',
        },
        {
            title:'Yes or No',
            key:'choice',
            dataIndex:'choice',
            width:"30%"
        }
    ]
    const data = [
        {
            key:'1',
            question:`Have you undergone any form of surgery in the past before ? 
            是否曾经有动过任何的手术？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="past surgery"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'2',
            question:`Do you have any mobility limitations?
            是否有任何行动不便或限制？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="mobility limitations"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'3',
            question:`Are you currently using a pacemaker？ 是否佩戴心律调整器？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="pacemaker"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'4',
            question:`Do you have asthma or any respiratory problems？是否患有哮喘或气管炎？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="asthma"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'5',
            question:`Do you have high blood pressure?
            是否患有高血压？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="high blood pressure"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'6',
            question:`Do you have low blood pressure？
是否患有低血压？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="low blood pressure"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'7',
            question:`Do you have diabetes？
是否患有糖尿病？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="diabetes"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'8',
            question:`Do you have any injuries or experience pain in your joints，neck，arms， legs and torso？
是否曾经患有关节炎，颈部，手脚或身体任何部位疼痛或受伤？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="joint injuries"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'9',
            question:`Do you have any heart problems？
是否患有心脏疾病？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="heart problems"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'10',
            question:`Do you have any protrusions of the intervertebral bones？是否患有椎间盘凸出？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="protrusions intervertebral bones"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'11',
            question:`Do you have cancer？是否患有癌症？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="cancer"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'12',
            question:`Have you undergone any form Osteopathic before ?是否曾经有做过正骨？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="Osteopathic"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'13',
            question:`Do you have vertebral slippage？是否有过椎骨滑脱？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="vertebral slippage"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'14',
            question:`Do you have Osteoporosis？是否患有骨质疏松症？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="Osteoporosis"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'15',
            question:`Do you have cardiopulmonary function problems？是否患有心肺功能问题？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="cardiopulmonary function"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
        {
            key:'16',
            question:`Do you have tumour？是否患有肿瘤？`,
            choice: <Radio.Group
                        options={options}
                        onChange={onChangeMedHistory}
                        defaultValue={false}
                        name="tumour"
                        optionType="button"
                        buttonStyle="solid"
                    />
        },
    ]
    return (
        <div className='therapist'>
            <Form
            form={form}
            name="control-hooks"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 0 }}
            style={{ maxWidth: 1000}}
            initialValues={{ disclaimer: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign='left'
            >
            <Form.Item
                name="therapist"
                label="Therapist"
                rules={[{ required: false, message: 'Please select therapist!' }]}
                allowClear    
            >
               {/** <Select>
                    {therapist.map((therapist) => 
                        <Option value={therapist}>
                            {therapist}
                        </Option>)}
                    </Select>*/}
                <Input />
            </Form.Item>
            <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="NRIC / PASSPORT No."
            name="ic"
            rules={[{ required: false, message: 'Please input your ic' }]}
            >
            <Input />
            </Form.Item>
            
            <Form.Item
            label="Contact No."
            name="contact"
            rules={[{ required: false, message: 'Please input your contact number!' }]}
            >
            <Input />
            </Form.Item>
            
            <Form.Item
            label="D.O.B"
            name="dob"
            rules={[{ required: false, message: 'Please input your date of birth!' }]}
            >
            <DatePicker onChange={onChange} />
            </Form.Item>
            
            <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: false, message: 'Please input your gender!' }]}
            >
            <Select>
                <Option value="male">
                        male
                </Option>
                <Option value="female">
                        female
                </Option>
            </Select>
            </Form.Item>

            <Form.Item
            label="Date"
            name="date"
            rules={[{ required: false, message: 'Please input the date!' }]}
            >
            <DatePicker onChange={onChange} defaultValue={dayjs(dateToday, dateFormat)} />
            </Form.Item>
            
            <Form.Item
            label="Remarks"
            name="remarks"
            >
            <TextArea />
            </Form.Item>

            <Form.Item
            label="Signature"
            name="signature"
            // rules={[{ required: false, message: 'Please sign here' }]}
            >
            {/*// @ts-ignore need ref*/}
                <SignatureCanvas 
                penColor='black' 
                canvasProps={{ className: 'sigCanvas'}} 
                ref={(ref) => { signCanvas = ref }}
                />           
                <Button htmlType="button" onClick={onClear}> Clear </Button>
            </Form.Item>

            <Form.Item
            label="Medical History"
            name="medicalhistory"
            >
                <Table columns={columns} dataSource={data} />
            </Form.Item>

            <Form.Item 
                name="disclaimer" 
                valuePropName="checked" 
                wrapperCol={{ offset: 8, span: 16 }}
                rules={[
                {
                    validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                },
                ]}
            >
            <div style={{maxWidth:"50vw"}}>
                <Checkbox>Declaration: I acknowledge that the above information given by me is complete and accurate to the best of my knowledge and that no fact that is likely to influence the safety of the treatment(s)that I have signed up for have been withheld.I also agreed that SANTE WELLNESS TCM staff to access my personal data for the provision of Healthcare Services. 我承认，根据我所知，我所提供的上诉信息是完整和准确的，并且没有可能影响我已注册的治疗的安全性的事实保留。我亦同意富康工 作人员查阅我提供医疗服务的个人资料。</Checkbox>
            </div>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Modal
                title="Submit Result"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
            >
                <p>{modalText}</p>
                <p className='modal-error'>{errorMsg}</p>
            </Modal>
            <Button type="primary" htmlType="submit" onClick={showModal}>
                Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
                Reset
            </Button>
            </Form.Item>
            
            </Form>
        </div>
    );
}

export default Therapist;